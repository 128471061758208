var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-5"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Простые карты режимов")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-cog")])],1)],1),_c('CardsTable',{key:_vm.reloadCardsTable,scopedSlots:_vm._u([{key:"select",fn:function(ref){
var card = ref.card;
return [(Number(card.status) !== 2)?_c('v-checkbox',{attrs:{"value":card.id},model:{value:(_vm.selectedCards),callback:function ($$v) {_vm.selectedCards=$$v},expression:"selectedCards"}}):_vm._e()]}},{key:"action",fn:function(ref){
var card = ref.card;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":'/special-card/card/update?step=1&id=' + card.id,"disabled":Number(card.status) !== 1}},[_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("mdi-file-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":'/special-card/card/copy?step=1&id=' + card.id}},[_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])])],1)]}}])}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default","disabled":_vm.selectedCards.length === 0},on:{"click":_vm.archiveCards}},[_vm._v(" В архив ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.createCard}},[_vm._v("Создать")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }